import React, {useEffect, useState}  from 'react'
import ExportExcel from './ExportExcel'
import { MDBDataTable } from 'mdbreact';
import {privateGET} from '../../services/Services';
const {REACT_APP_ENDPOINT, REACT_APP_FILES_URL} = process.env;

 const data={
    columns:[
        {
    label: 'Fecha',
    field: 'date',
    sort: 'asc',
    width: 150
},
{
    label: 'Nombre',
    field: 'name',
    sort: 'asc',
    width: 150
},
{
    label: 'Apellido',
    field: 'last_name',
    sort: 'asc',
    width: 150
},
{
    label: 'Mail',
    field: 'email',
    sort: 'asc',
    width: 150
},
{
    label: 'Teléfono',
    field: 'phone',
    sort: 'asc',
    width: 150
},
{
    label: 'DNI',
    field: 'dni',
    sort: 'asc',
    width: 150
},
{
    label: 'Mensaje',
    field: 'content',
    sort: 'asc',
    width: 150
},
{
    label: 'Currículum',
    field: 'cv',
    sort: 'asc',
    width: 150
}
],

    }

const CurriculumTable = () => {
    const fileName= 'Currículums'
    const [candidateData, setCandidateData] = useState({
        columns: data.columns,
        rows: null
      });

    useEffect(()=> {
        privateGET(`${REACT_APP_ENDPOINT}public-api/cv`).then(r => {
          const newData = r.data.map(d => {
            return {
                id:1,
                date:d.createdAt.slice(0, -14),
                name: d.name,
                last_name: d.last_name,
                email: d.email,
                phone: d.phone,
                dni: d.dni,
                content: d.message,
                cv: <a href={REACT_APP_FILES_URL+d.filename} download>{d.filename}</a>
            }
          })
          setCandidateData({...data, rows: newData});
        })
      },[])
  return (
    
    <div className='table-box'>
   <MDBDataTable
   striped
   bordered
   hover
   data={candidateData} 
   />
   <ExportExcel apiData={candidateData.rows} fileName={fileName}/>
   </div>
  )
}

export default CurriculumTable