import React, {useEffect, useState} from 'react';
import './contactTable.css'
import { MDBDataTable } from 'mdbreact';
import ExportExcel from './ExportExcel';
import {privateGET} from '../../services/Services';
const {REACT_APP_ENDPOINT} = process.env;

const data={
  columns: [
    {
      label: 'Fecha',
      field: 'createdAt',
      sort: 'asc',
      width: 150
    },  
    {
      label: 'Nombre',
      field: 'name',
      sort: 'asc',
      width: 150
    },
      {
      label: 'Apellido',
      field: 'lastName',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Mail',
      field: 'email',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Broker',
      field: 'broker',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Mensaje',
      field: 'content',
      sort: 'asc',
      width: 150
    }
  ]
}
const ContactTable = () => {
const fileName= 'Contactos'
const [contactData, setContactData] = useState({
  columns: data.columns,
  rows: null
});

useEffect(()=> {
  privateGET(`${REACT_APP_ENDPOINT}public-api/contact`).then(r => {
    const newData = r.data.map(d => {
      return {
        createdAt: d.createdAt.slice(0, -14),
        name: d.name,
        lastName: d.last_name,
        email: d.email,
        broker: d.is_broker === 0 ? "Si" : "No",
        content: d.message
      }
      
    })
    setContactData({...data, rows: newData});
  })
},[])

  return (
   <div className='table-box'>
    {contactData.rows && (
      <>
        <MDBDataTable
        striped
        bordered
        hover
        data={contactData} 
        />
        <ExportExcel apiData={contactData.rows} fileName={fileName}/>
        </>
      )}
   </div>
 
  )
}

export default ContactTable