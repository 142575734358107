import React, {useEffect, useState} from 'react';
import ExportExcel from './ExportExcel'
import { MDBDataTable } from 'mdbreact';
import {privateGET} from '../../services/Services';
const {REACT_APP_ENDPOINT} = process.env;

const data={
    columns:[
        {
    label: 'Fecha',
    field: 'createdAt',
    sort: 'asc',
    width: 150
},
{
    label: 'Nombre',
    field: 'name',
    sort: 'asc',
    width: 150
},
{
    label: 'Apellido',
    field: 'lastName',
    sort: 'asc',
    width: 150
},
{
    label: 'Mail',
    field: 'email',
    sort: 'asc',
    width: 150
},
{
    label: 'CUIT',
    field: 'cuit',
    sort: 'asc',
    width: 150
},
{
    label: 'Dirección',
    field: 'adress',
    sort: 'asc',
    width: 150
},
{
    label: 'Empresa',
    field: 'companyName',
    sort: 'asc',
    width: 150
},
{
    label: 'Dirección Empresa',
    field: 'companyAdress',
    sort: 'asc',
    width: 150
},
{
    label: 'Monto',
    field: 'clientAmount',
    sort: 'asc',
    width: 150
},
{
    label: 'Facturar a',
    field: 'billName',
    sort: 'asc',
    width: 150
},
{
    label: 'Mail',
    field: 'billEmail',
    sort: 'asc',
    width: 150
},
{
    label: 'CUIT',
    field: 'billCuit',
    sort: 'asc',
    width: 150
},
{
    label: 'Teléfono',
    field: 'billPhone',
    sort: 'asc',
    width: 150
},
{
    label: 'Dirección',
    field: 'billAdress',
    sort: 'asc',
    width: 150
},

]}

const Directores = () => {
     const fileName= 'Cotizaciones Directores'
     const [directorData, setDirectorData] = useState({
        columns: data.columns,
        rows: null
      });

      useEffect(()=> {
        privateGET(`${REACT_APP_ENDPOINT}public-api/directors`).then(r => {
          const newData = r.data.map(d => {
            return {
                createdAt: d.createdAt.slice(0, -14),
                name: d.name,
                lastName: d.last_name,
                email: d.email,
                cuit: d.cuit,
                adress: d.adress,
                companyName: d.company_name,
                companyAdress: d.company_adress,
                clientAmount: d.amount,
                billName: d.bill_name,
                billEmail: d.bill_email,
                billCuit: d.bill_cuit,
                billPhone: d.bill_phone,
                billAdress: d.bill_adress
            }
            
          })
          setDirectorData({...data, rows: newData});
        })
      },[])
  return (
    <div>       
    <MDBDataTable
    striped
    bordered
    hover
    data={directorData} 
    />
    <ExportExcel apiData={directorData} fileName={fileName}/>
    
    </div>
  )
}

export default Directores