import React, {useEffect, useState} from 'react'
import ExportExcel from './ExportExcel'
import { MDBDataTable } from 'mdbreact';
import {privateGET} from '../../services/Services';

const {REACT_APP_ENDPOINT} = process.env;

const data={
    columns:[
        {
    label: 'Fecha',
    field: 'date',
    sort: 'desc',
    width: 150
},
{
    label: 'Nombre',
    field: 'userName',
    sort: 'asc',
    width: 150
},
{
    label: 'Apellido',
    field: 'userLastName',
    sort: 'asc',
    width: 150
},
{
    label: 'Empresa',
    field: 'company',
    sort: 'asc',
    width: 150
},
{
    label: 'Mail',
    field: 'email',
    sort: 'asc',
    width: 150
},
{
    label: 'Teléfono',
    field: 'phone',
    sort: 'asc',
    width: 150
},
{
    label: 'DNI',
    field: 'dni',
    sort: 'asc',
    width: 150
},
{
    label: 'Cómo llegó?',
    field: 'howToKnow',
    sort: 'asc',
    width: 150
},
{
  label: 'Curso',
  field: 'courseName',
  sort: 'asc',
  width: 150
}
]}

const SchoolTable = () => {
    const fileName= 'InscriptosEscuela'
    const [enrolledSchooltData, setEnrolledSchooltData] = useState({
        columns: data.columns,
        rows: null
      });

useEffect(()=> {
  privateGET(`${REACT_APP_ENDPOINT}public-api/school`).then(response => {
    const newData = response.data.map(r => {
      return {
        id: r.id,
        date: r.createdAt.slice(0, -14),
        userName: r.name,
        userLastName: r.last_name,
        company: r.company,
        email: r.email,
        phone: r.phone,
        dni: r.dni,
        courseName: r.course.course_name,
        howToKnow: r.how_to_know
      }
    })
    setEnrolledSchooltData({...data, rows: newData});
  })
},[])

    return (
    <div className='table-box'>
    {enrolledSchooltData.rows && (
      <>
        <MDBDataTable
        striped
        bordered
        hover
        data={enrolledSchooltData} 
        />
        <ExportExcel apiData={enrolledSchooltData.rows} fileName={fileName}/>
        </>
      )}
    </div>
  )
}

export default SchoolTable

